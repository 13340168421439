.support {
    position: relative;
    height: 840px;
    // background: url('../../../assets/last-bg.svg');
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: cover;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #161616;

    .circlemain {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 672px;
        width: 100%;
        height: 672px;
        .circleone,
        .circletwo,
        .circlethree {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            opacity: 0.3;
            animation-name: moveAndRotate;
            animation-duration: 10s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            animation-direction: alternate;
        }
        
        .circleone {
            border: 1px solid white;
            animation-duration: 20s;
            // top: 0;
        }
        
        .circletwo {
            border: 1px solid white;
            animation-duration: 15s;
            // top: 10px;
        }
        
        .circlethree {
            border: 1px solid white;
            animation-duration: 10s;
            // top: 20px;
        }
        
        @keyframes moveAndRotate {
            0% {
                transform: rotate(0deg) translate(-7.5px, 7.5px);
            }
            50% {
                transform: rotate(360deg) translate(15px, -15px);
            }
            100% {
                transform: rotate(720deg) translate(-22.5px, 22.5px);
            }
        }
    }
    

    

    
 
    
    // .supportbg{
    //     z-index: -99;
    //     width: 100%;
    //     position: absolute;
    //     left: 0;
    //     bottom: 0;
    //     right: 0;
    //     top: 0;
    // }

    .supportmain {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .supporttoppara {
            color: #FFF;
            text-align: center;
            font-size: 21px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            letter-spacing: 1.05px;
            text-transform: uppercase;
            margin-bottom: 20px;
        }

        .supporthead {
            color: #FFF;
            text-align: center;
            font-size: 52px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            text-transform: uppercase;
            max-width: 1058px;
            width: 100%;
            margin: 0 auto 30px;

            .boldtext {
                font-weight: 700;
            }
        }

        .supportlastpara {
            color: #FFF;
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            text-transform: uppercase;
            max-width: 528px;
            width: 100%;
            margin: 0 auto;
        }

        .supportbtns {
            display: flex;
            align-items: flex-start;
            gap: 30px;
            margin-top: 40px;

            .whitebtn {
                background: #FFF;
                backdrop-filter: blur(7px);
                display: flex;
                padding: 18px 20px;
                justify-content: center;
                align-items: center;
                color: #000;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                border: none;
                outline: none;
                transition: 0.3s linear;

                &:hover {
                    background: #BBB;
                }
            }

            .greybtn {
                background: rgba(255, 255, 255, 0.40);
                backdrop-filter: blur(7px);
                display: flex;
                padding: 18px 20px;
                justify-content: center;
                align-items: center;
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                border: none;
                outline: none;
                transition: 0.3s linear;

                &:hover {
                    background: rgba(255, 255, 255, 0.20);
                }
            }
        }
    }
}

@media(max-width:991px){
    .support{
        display: flex !important;
        justify-content: center;
        align-items: center;
        min-height: auto !important;
    }
    .support .supportmain .supporthead{
        font-size: 50px;
        max-width: 780px;
    }
}

@media(max-width:600px){
    .support{
        height: 586px;
        padding-top: 160px;
    }
    .support .supportmain .supporthead{
        font-size: 18px;
        max-width: 430px;
    }
    .support .supportmain .supporttoppara{
        font-size: 18px;
    }
    .support .supportmain .supportlastpara{
        font-size: 14px;
        max-width: 386px;
    }
    .support .supportmain .supportbtns{
        margin-top: 40px;
    }
}

@media(max-width:1024px){
    .support .circlemain{
        height: 500px;
        max-width: 500px;
    }
}

@media(max-width:768px){
    .support .circlemain{
        height: 300px;
        max-width: 300px;
    }
}

@media(max-width:480px){
    .support .circlemain{
        height: 270px;
        max-width: 270px;
    }
}

@media(max-width:420px){
    .support .circlemain{
        height: 220px;
        max-width: 220px;
    }
}

@media(max-width:600px){
    .support{
        height: 495px;
        padding-top: 0px;
    }
}