.bgstyling {
    // background: url('../../../assets/collective-bg.svg');
    // background-size: cover;
    // background-position: center;
    // background-repeat: no-repeat;
    height: 100vh;
    position: relative;
   

    .sectioninnerstyling {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 54px;
        height: 100%;

        .sectionhead {
            color: #FFF;
            text-align: center;
            font-size: 55px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            text-transform: uppercase;
            max-width: 400px;
            width: 100%;
            margin: 0 auto;

            .boldest {
                font-weight: 700;
            }
        }
    }
}

.bgstyling .set-custom-parent {
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    button{
        display: block;
        margin: 0 auto;
    }
    .btn-mexc{
        border: 1px solid #FFF;
        background: rgba(255, 255, 255, 0.03);
        backdrop-filter: blur(2px);
        padding: 18px 20px;
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.bgstyling .nothing-text-banner1 {
    position: absolute;
    top: -100px;
    left: 0;
    border: 1px solid #1A1A1A;
    background: #0F0F0F;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.65);
    padding: 20px 40px;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-transform: uppercase;
    width: 378px;
}


@media(max-width:1024px){
    .bgstyling{
        background: url('../../../assets/collective-bg-mbl.svg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 626px;
        overflow: hidden;
    }
    .bgstyling .sectioninnerstyling .sectionhead{
        font-size: 40px;
        max-width: 310px;
    }
    .bgstyling .sectioninnerstyling{
        gap: 45px;
    }
    .bgstyling .set-custom-parent{
        width: 100%;
    }
    .bgstyling .nothing-text-banner1{
        padding: 15px !important;
        top: -80px !important;
        left: 0 !important;
    }
}

