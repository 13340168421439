.main-footer {
    background: #131313;
    padding-top: 63px;
    padding-bottom: 58px;

    .footermain {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .footerleft {
            .footerlinks {
                display: flex;
                align-items: center;
                gap: 30px;
                margin-top: 41px;

                .footlink {
                    color: #FFF;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 100%;
                    text-transform: uppercase;
                    text-decoration: none;
                    transition: 0.3s linear;
                    border-bottom: 1px solid transparent;

                    &:hover {
                        border-bottom: 1px solid #fff;
                    }
                }
            }
        }

        .footerrightpara {
            color: #FFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            text-transform: uppercase;
        }
    }
}

.footertext {
    display: flex;
    align-items: center;
    background: #0E0E0E;
    padding: 18px 0px;
    justify-content: center;
    width: 100%;

    .footertextpara {
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        text-transform: uppercase;
    }
}

@media(max-width:786px) {
    .main-footer .footermain {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .main-footer .footermain .footerleft {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .main-footer {
        padding: 58px 0px;
    }

    .main-footer .footermain .footerrightpara {
        margin-top: 40px;
    }
    .formobilesetposition{
        right: unset !important;
        left: -50px !important;
    }
    .main-footer .footermain .footerleft .footerlinks .footlink{
        font-size: 14px;
    }
}

.main-footer .set-parent {
    position: relative;
}

.nothing-text {
    position: absolute;
    top: -60px;
    right: -60px;
    border: 1px solid #1A1A1A;
    background: #0F0F0F;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.65);
    padding: 20px 40px;
    color: #FFF;
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-transform: uppercase;
}