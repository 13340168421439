.main-banner {
    min-height: 100vh;
    position: relative;
    z-index: 50;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #000;

    .bannermoonmain {
        position: absolute;
        bottom: 0px;
        right: 0px;
        z-index: -1;
        height: 550px;
        width: 550px;
        perspective: 1000px;

        .bannercircle,
        .bannercircleline {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }

        .bannercircle{
            transform-style: preserve-3d;
            animation: moveAndRotat 4s linear infinite;
        }

        .bannercircleline {
            transform-style: preserve-3d;
            animation: moveAndRotate1 4s linear infinite;
        }

        @keyframes moveAndRotate1 {
            0% {
               top: 0px;
               left: 20px;
            }

            50% {
                  top: -30px;
               left: 50px;
            }

             100% {
                 top: 0px;
               left: 20px;
            }


        }
        @keyframes moveAndRotat {
            0% {
                top: 0px;
                left: 20px;
             }
 
             50% {
                   top: -20px;
                   left: 50px;
             }
 
              100% {
                  top: 0px;
                  left: 20px;
             }


        }
    }

    // .bg-layer{
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     z-index: 5;
    //     background-color: rgba(0,0,0,0.4);
    //     width: 100%;
    //     height: 100%;
    //     pointer-events: none;
    // }

    // .banner-bg-video{
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     z-index: -1;
    //     object-fit: cover;
    // }

    // .banner-bg {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     z-index: -1;
    //     pointer-events: none;
    //     width: 100%;
    //     height: 100%;
    //     object-fit: cover;
    // }

    .btn-more {
        position: absolute;
        bottom: 35px;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 45;

        h6 {
            color: #FFF;
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            text-transform: uppercase;
            margin-bottom: 45px;
        }
    }

    .top-logo {
        text-align: center;
        position: absolute;
        top: 35px;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 45;

    }

    .main-content {
        padding-top: 50px;
        position: relative;
        z-index: 45;

        h5 {
            color: #FFF;
            text-align: center;
            font-size: 21px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            letter-spacing: 1.05px;
            text-transform: uppercase;
            margin-bottom: 20px;
        }

        h2 {
            color: #FFF;
            text-align: center;
            font-size: 90px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            text-transform: uppercase;
            max-width: 894px;
            width: 100%;
            margin: 0 auto;

            span {
                color: #FFF;
                font-size: 90px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                text-transform: uppercase;
            }
        }

        h6 {
            color: #FFF;
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            text-transform: uppercase;
            margin-top: 30px;
            margin-bottom: 40px;
        }

        .bottom-btns {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 30px;

            .btn-buy {
                background: #FFF;
                backdrop-filter: blur(7px);
                padding: 18px 20px;
                color: #000;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                font-family: 'PT Mono', monospace;
                max-width: 185px;
                width: 100%;
                text-align: center;
                transition: 0.3s linear;

                &:hover {
                    background: #BBB;
                }
            }

            .btn-whitepaper {
                background: rgba(255, 255, 255, 0.40);
                backdrop-filter: blur(7px);
                padding: 18px 20px;
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                max-width: 185px;
                width: 100%;
                text-align: center;
                transition: 0.3s linear;

                &:hover {
                    background: rgba(255, 255, 255, 0.20);
                }
            }

            .btn-mexc{
                border: 1px solid #FFF;
                background: rgba(255, 255, 255, 0.03);
                backdrop-filter: blur(2px);
                padding: 18px 20px;
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
}

.main-banner .set-custom-parent {
    position: relative;
    text-align: center;
}

.nothing-text-banner {
    position: absolute;
    top: -100px;
    right: 0;
    border: 1px solid #1A1A1A;
    background: #0F0F0F;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.65);
    padding: 20px 40px;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-transform: uppercase;
    width: 378px;
}



@media (max-width:1024px) {
    .main-banner {
        min-height: 95vh;
        // padding: 142px 0;
    }

    .main-banner .main-content h5 {
        font-size: 20px;
    }

    .main-banner .main-content h2 span {
        font-size: 36px;
    }

    .main-banner .banner-bg {
        display: none;
    }

    .main-banner .banner-bg-mbl {
        display: block !important;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        pointer-events: none;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .mobileeightauto {
        height: auto !important;
    }

    .main-banner .main-content {
        padding-top: 0;
    }

    .main-banner .btn-more {
        bottom: 80px;
    }

    .nothing-text-banner {
        top: -98px !important;
        left: -10px !important;
        right: unset !important;
        width: max-content !important;
        padding: 15px !important;
    }
    .main-banner .main-content .bottom-btns{
        flex-direction: column;
        gap: 10px;
    }
    .main-banner .main-content .bottom-btns .btn-buy{
        display: block;
        max-width: 100%;
        width: 100%;
    }
    .main-banner .set-custom-parent{
        width: 100%;
    }
    .main-banner .main-content .bottom-btns .btn-whitepaper{
        max-width: 100%;
        display: block;
    }
    .main-banner .main-content .bottom-btns .btn-mexc{
        max-width: 100%;
        display: block;
    }
}

@media (max-width: 375px) {
    .main-banner .main-content .bottom-btns .btn-buy {
        font-size: 12px !important;
    }
}





.arrow,
.arrow:before {
    position: absolute;
    left: 50%;
}

.arrow {
    width: 20px;
    height: 20px;
    top: 50%;
    margin: -10px 0 0 -10px;
    transform: rotate(45deg);
    border-left: none;
    border-top: none;
    border-right: 2px #fff solid;
    border-bottom: 2px #fff solid;

    &:before {
        content: '';
        width: 10px;
        height: 10px;
        top: 50%;
        margin: 0px 0 0 0px;
        border-left: none;
        border-top: none;
        border-right: 1px #fff solid;
        border-bottom: 1px #fff solid;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-name: arrow;
    }
}

@keyframes arrow {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translate(-10px, -10px);
    }
}

@media(min-width:2000px) {
    .main-banner .bannermoonmain {
        height: 68vw;
        width: 68vw;
        bottom: -30vw;
    }
}

@media(max-width:990px) {
    .main-banner .bannermoonmain {
        left: 50px;
        right: unset;
    }
}