.journey{
    background: #F6F6F6;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .main-content{
        position: relative;
        .text-abs{
            position: absolute;
            top: -20px;
            left: 0;
            pointer-events: none;
        }
        h6{
            color: #000;
            font-size: 55px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            text-transform: uppercase;
            padding-bottom: 17px;
            span{
                color: #000;
                font-size: 55px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                text-transform: uppercase;
            }
        }
        .line-draw{
            width: 256px;
            height: 2px;
            background-color: #000;
            display: flex;
        }
        p{
            color: #000;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%; 
            text-transform: uppercase;
            margin-top: 54px;
            max-width: 496px;
            width: 100%;
            font-family: 'PT Mono', monospace;
        }
    }
    .set-padding-custom{
        padding-right: 80px;
        animation: paddingopax 3s infinite;
    }
}

@media (max-width:1024px){
    .journey{
        min-height: 95vh;
        padding: 142px 0;
    }
    .journey .img-wrapper{
        display: none;
    }
    .journey .main-content h6{
        font-size: 42px;
    }
    .journey .main-content h6 span{
        font-size: 42px;
    }
    .journey-mbl{
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        display: block !important;
    }
    .journey .main-content{
        margin-top: 140px;
    }
    .journey .main-content p{
        margin-top: 30px;
    }
    .journey .main-content .text-abs{
        width: 216px;
        top: -20px;
    }
}