.bgstyling {
    // background: url('../../../assets/collective-bg.svg');
    // background-size: cover;
    // background-position: center;
    // background-repeat: no-repeat;
    height: 100vh;
    position: relative;

    .sectioninnerstyling {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 54px;
        height: 100%;

        .sectionheadhundred{
            max-width: 100% !important;
        }

        .sectionhead {
            color: #FFF;
            text-align: center;
            font-size: 55px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            text-transform: uppercase;
            max-width: 400px;
            width: 100%;
            margin: 0 auto;

            .boldest {
                font-weight: 700;
            }
        }
    }
}


@media(max-width:1024px){
    .bgstyling{
        background: url('../../../assets/collective-bg-mbl.svg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 626px;
    }
    .bgstyling .sectioninnerstyling .sectionhead{
        font-size: 40px;
        max-width: 310px;
    }
    .bgstyling .sectioninnerstyling{
        gap: 45px;
    }
}

.set-custom-parallax{
    position: absolute !important;
    z-index: -1;
}