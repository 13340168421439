.creator{
    background: #EBEBEB;
    min-height: calc(100vh - 100px);
    padding-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    .main-content{
        h6{
            color: #000;
            font-size: 55px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; 
            text-transform: uppercase;
            margin-bottom: 57px;
            span{
                color: #000;
                font-size: 55px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                text-transform: uppercase;
            }
        }
        p{
            color: #000;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%; 
            text-transform: uppercase;
            margin-bottom: 30px;
            max-width: 552px;
            width: 100%;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}

@media (max-width:1024px){
    .creator{
        min-height: unset;
        padding: 142px 0;
        padding-bottom: 20px;
    }
    .creator{
        padding-top: 100px;
    }
    .creator .main-content h6{
        font-size: 42px;
        span{
            font-size: 42px;
        }
    }
    .creator .main-content p:last-child{
        margin-bottom: 30px;
    }
}