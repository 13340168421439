.essence{
    background: #F6F6F6;
    padding: 142px 0;
    position: relative;
    z-index: 50;
    overflow: hidden;
    .fairlaunch{
        position: absolute;
        top: 0;
        right: 0;
        pointer-events: none;
        z-index: -1;
        animation: paddingopax 3s infinite;
    }
    .main-heading{
        h6{
            color: #000;
            font-size: 55px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            text-transform: uppercase;
            padding-bottom: 17px;
            span{
                color: #000;
                font-size: 55px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                text-transform: uppercase;
            }
        }
        .line-draw{
            width: 256px;
            height: 2px;
            background-color: #000;
            display: flex;
        }
    }
    .bottom-content{
            margin-top: 54px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 35px;
            .single{
                flex: 0 0 360px;
                h6{
                    color: #000;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 130%; 
                    text-transform: uppercase;
                    margin-bottom: 4px;
                }
                p{
                    color: #000;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 130%;
                    text-transform: uppercase;
                }
            }
    }
}

@media (max-width:1024px){
    .essence .main-heading h6{
        font-size: 42px;
    }
    .essence .main-heading h6 span{
        font-size: 42px;
    }
    .essence .bottom-content{
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 55px;
    }
    .essence .bottom-content .single{
        flex: 0 0 100%;
    }
    .essence .fairlaunch{
        display: none;
    }
    .fairlaunch-mbl{
        display: block !important;
        position: absolute;
        bottom: 0;
        right: 0;
        pointer-events: none;
        z-index: -1;
        animation: paddingopax 3s infinite;
    }
}